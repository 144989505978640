export class AppUser {
    userName = '';
    password = '';
    culture = '';
cultureId = '';
company = '';
accountName = '';
StripeSessionId=''

}
