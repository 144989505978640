import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import * as jose from 'jose';
import { Guid } from 'guid-typescript';
import { DOCUMENT } from '@angular/common';
import { DataRowOutlet } from '@angular/cdk/table';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable()
export class SecurityService {
    private _renderer2: Renderer2;
    private canonicalName: string;
    securityObject: any;
    APIURL = '';
    private appUrl = environment.appUrl;
    private isOnPremise = environment.isOnPremise;
    sManagerUrl = environment.sManagerUrl;
    outlookRedirectUrl = '';

    private _isExpired = false;
    public get isExpired() {
        return this._isExpired;
    }
    public set isExpired(value) {
        this._isExpired = value;
    }

    constructor(
        public router: Router,
        private http: HttpClient,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private _document: Document
    ) {
        if (!this.securityObject?.userId) {
            if (localStorage.getItem('securityObject')) {
                this.securityObject = JSON.parse(localStorage.getItem('securityObject'));
                this.showHelpIcon();
            } else {
                this.logout();
                this.hideHelp();
            }
        }
        const newurl = window.location.href;

        if (this.isOnPremise) {
            this.APIURL = this.appUrl;
            this.outlookRedirectUrl = this.APIURL.replace('/api/v1/', '/imp.html');
        } else {
            const newAPI = newurl.split('.')[0] + '.api.' + newurl.substring(newurl.indexOf('.') + 1);
            this.APIURL = newAPI.split('#')[0] + 'api/v1/';
            this.outlookRedirectUrl = newAPI.split('#')[0] + 'imp.html';
        }

        if (this.APIURL.search('localhost') > -1) {
            // this.APIURL = 'https://main.api.sharewinds.com/api/v1/';
            this.APIURL = environment.localHostUrl;
            this.outlookRedirectUrl = environment.localHostUrl.replace('/api/v1/', '/imp.html');
        }
        // this.APIURL = "https://localhost:44342/api/v1/";
        //this.APIURL = 'https://longco3.api.sharewinds.com/api/v1/';

        if (this.isOnPremise) {
            this.activateSilentOutlookAuth();
            setInterval(() => {
                this.activateSilentOutlookAuth();
            }, 1800 * 1000);
        }


    }

    login(appUser) {
        this.resetSecurityObject();
        //var justforNow = 'https://devenv.api.sharewinds.com/api/v1/';
        return this.http.post<any>(`${this.APIURL}security/sUser/login/`, appUser).pipe(
            //return this.http.post<any>(`${justforNow}security/sUser/login/`, appUser).pipe(
            tap((resp) => {
                if (resp.data) {
                    localStorage.setItem('securityObject', JSON.stringify(resp.data));
                    this.securityObject = resp.data;
                    // sessionStorage.setItem('contactId', this.securityObject.contactId);
                    // sessionStorage.setItem('dateFormate', this.securityObject.dateFormat);
                    localStorage.setItem('dateFormate', this.securityObject.dateFormat);

                    this.createJWTForZendesk().then((token) => {
                        localStorage.setItem('zdjwt-x0', token);
                    });
                }
            })
        );
    }

    async createJWTForZendesk() {
        const secret = new TextEncoder().encode('7f83f17ff38d7b9e9433844bd0252695');

        const alg = 'HS256';

        const jwt = await new jose.SignJWT({
            name: 'Radwa Fouad',
            email: 'rmostafa@technosignage.com'
        })
            .setProtectedHeader({ alg })
            .setIssuedAt()
            .setJti(Guid.create().toString())
            .sign(secret);

        //console.log(jwt);
        return jwt;
    }

    sendCancellationRequest() {
        var accountName = this.APIURL.split('.api.')[0].replace('https://', '').replace('http://', '');
        // var accountName = "Comp3";
        return this.http.post<any>(`${this.sManagerUrl}/api/Subs/SendSubsCancellationRequest/${accountName}`, {});
    }

    recoverPassword(userEmail, langName) {
        return this.http.post<any>(`${this.APIURL}security/sUser/recoverpassword/${userEmail}/${langName}`, {});
        //return this.http.post<any>(`http://localhost:56622/api/v1/security/sUser/recoverpassword/${userEmail}/${langName}`, {});
    }

    checkLinkExpiration(link: any) {
        // return this.http.post<any>(`${this.APIURL}security/sUser/Expier?link=${link}`, {} , { params : { link : link} } );
        return this.http.post<any>(`${this.APIURL}security/sUser/Expier?link=${link}`, {});
    }

    restUserPassword(appUserObj: any) {
        return this.http.post<any>(`${this.APIURL}security/sUser/forget-password/`, appUserObj);
        //return this.http.post<any>(`http://localhost:56622/api/v1/security/sUser/recoverpassword/${userEmail}/${langName}`, {});
    }

    CheckAccountName(data)
    {
        return this.http.post<any>(`https://smanagerdev.sharewinds.com/api/v1/SubscriptionManager/VerifySubscriptionName/${data}`, {});

    }
    getDomain(userdata) {
        return this.http.post<any>(`http://xzycompany.sharewinds.com/api/Subs/getSub/${userdata}`, {});
    }
    checkuserexist(userdata) {
        return this.http.post<any>(`${this.APIURL}security/sUser/check-mailorusername`, { email: userdata });
    }
    Activate(name,user)
    {
        return this.http.post<any>(`https://smanagerdev.sharewinds.com/api/v1/SubscriptionManager/activate/${name}`, user);

    }
    logout(): void {
        this.resetSecurityObject();
    }
    getLookUp() {
        return this.http.get<any>(`${this.APIURL}lookup/get-menuItems`);
    }
    resetSecurityObject(): void {
        localStorage.setItem('userdata', null);
        localStorage.removeItem('lastSelectedResources');
        localStorage.removeItem('securityObject');
        if (this.securityObject) {
            this.securityObject.userName = '';
            this.securityObject.companyCode = '';
            this.securityObject.countryId = '';
            this.securityObject.countryCode = '';
            this.securityObject.bearerToken = '';
            this.securityObject.parentUserId = '';
            this.securityObject.parentUserName = '';
            this.securityObject.isAuthenticated = false;
        }
        this.securityObject = null;

        // this.securityObject.claims = [];
    }

    getUserBoundedEmails(contactId) {

        return this.http.get<any>(`${this.APIURL}Outlook/GetUserBoundedEmails/` + contactId);
    }

    unBindEmail(entryId) {
        return this.http.post<any>(`${this.APIURL}Outlook/UnboundOutlookEmail/${entryId}`, {});

    }
    // This method can be called a couple of different ways
    // *hasClaim="'claimType'"  // Assumes claimValue is true
    // *hasClaim="'claimType:value'"  // Compares claimValue to value
    // *hasClaim="['claimType1','claimType2:value','claimType3']"

    // permationControl
    // to back permationControl remove the comment of the two function hasClaim and isClaimValid with return ret. and comment return true
    // note that permationControl can't be null

    hasClaim(claimType: any, claimValue?: any) {
        // let ret: boolean = false;
        // //console.log("login", claimType);
        // //console.log("login", claimValue);
        // // See if an array of values was passed in.
        // if (typeof claimType === "string") {
        //     ret = this.isClaimValid(claimType, claimValue);
        //     //console.log("ret", ret);
        // }
        // else {
        //     let claims: string[] = claimType;
        //     if (claims) {
        //         for (let index = 0; index < claims.length; index++) {
        //
        //             ret = this.isClaimValid(claims[index]);
        //             // If one is successful, then let them in
        //             if (ret) {
        //                 break;
        //             }
        //         }
        //     }
        // }
        //         return ret;

        return true;
    }

    private isClaimValid(claimType: string, claimValue?: string): boolean {
        // let ret: boolean = false;
        // let auth: AppUserAuth = null;

        // // Retrieve security object
        // auth = this.securityObject;
        // //console.log("auth", auth);
        // if (auth) {
        //     // See if the claim type has a value
        //     // *hasClaim="'claimType:value'"
        //     if (claimType.indexOf(":") >= 0) {
        //         let words: string[] = claimType.split(":");
        //         claimType = words[0].toLowerCase();
        //         claimValue = words[1];
        //     }
        //     else {
        //         claimType = claimType.toLowerCase();
        //         // Either get the claim value, or assume 'true'
        //         claimValue = claimValue ? claimValue : "true";
        //     }
        //     // Attempt to find the claim

        //     ret = auth.claims.find(c =>
        //         c.permissionControl.toLowerCase() == claimType &&
        //         c.permissionValue.toString() == claimValue) != null;
        // }
        //         return ret;
        return true;
    }

    showHelpIcon() {
        this._renderer2 = this.rendererFactory.createRenderer(null, null);
        const JWTScript = this._renderer2.createElement('script');
        JWTScript.innerHTML = `
                    window.zESettings = {
                        webWidget: {
                            authenticate: {
                                jwtFn: function (callback) {
                                    callback( localStorage.getItem('zdjwt-x0'));
                                }
                            }
                        }
                    };
              `;
        const script = this._renderer2.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=1d776112-70fd-47be-89fb-96201988b730';
        this._renderer2.appendChild(this._document.body, JWTScript);
        this._renderer2.appendChild(this._document.body, script);
        const element02 = document.querySelector('iframe[data-product="web_widget"]');
        const element03 = document.querySelector('iframe#launcher');
        const element04 = document.querySelector('iframe#webWidget');
        if (element02) element02.classList.toggle('d-none');
        if (element03) element03.classList.toggle('d-none');
        if (element04) element04.classList.toggle('d-none');
    }

    hideHelp() {
        const element = document.getElementById('ze-snippet');
        const element02 = document.querySelector('iframe[data-product="web_widget"]');
        const element03 = document.querySelector('iframe#launcher');
        const element04 = document.querySelector('iframe#webWidget');
        if (element) element.remove();
        if (element02) element02.classList.toggle('d-none');
        if (element03) element03.classList.toggle('d-none');
        if (element04) element04.classList.toggle('d-none');
    }


    activateSilentOutlookAuth() {

        const OuLsString = localStorage.getItem('ouBoundedList');
        const OuLsStringAsJson = JSON.parse(OuLsString);

        if (localStorage.getItem('securityObject') && this.isOnPremise && OuLsStringAsJson && OuLsStringAsJson?.length > 0) {
            this.removeOutlookSilentAuth();

            const secString = localStorage.getItem('securityObject');
            const secObjAsJson = JSON.parse(secString);

            //var login_hint = "ihab_saeed_mmc@hotmail.com";

            var login_hint = OuLsStringAsJson[0].outlookEmail;

            var endpoint = '';
            if (this.APIURL.toLocaleLowerCase().includes('http://'))
                endpoint = this.APIURL.replace('http://', '');
            if (this.APIURL.toLocaleLowerCase().includes('https://'))
                endpoint = this.APIURL.replace('https://', '');

            var statVal = `${secObjAsJson.contactId}_${endpoint}Outlook/ImpilictFlowEndpoint`;
            //"98d3617f-7b89-482c-afa8-eca93db8302f_localhost:8988/api/v1/Outlook/ImpilictFlowEndpoint";
            // var redirect_uri = "http%3A%2F%2Flocalhost%3A8988%2Fimp.html";
            var redirect_uri = this.outlookRedirectUrl;

            if (localStorage.getItem('ouEmail'))
                login_hint = localStorage.getItem('ouEmail'); // will add later

            var iframe = document.createElement("iframe"); // create an iframe element
            iframe.id = 'ouInte';
            // set the iframe's "src" attribute to the login page URL
            iframe.src =
                "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=275d68f6-c885-492b-b538-75957f10c202&response_type=token&redirect_uri=" +
                redirect_uri +
                "&scope=https%3A%2F%2Fgraph.microsoft.com%2FCalendars.ReadWrite&response_mode=fragment&state=" +
                statVal +
                "&nonce=678910&prompt=none&login_hint=" +
                login_hint;

            // hide the iframe
            iframe.style.display = "none";

            // attach an event listener to the iframe's "load" event
            iframe.addEventListener("load", function () {
                console.log("ouLoaded");

                // // check if the URL of the iframe's content window contains the access token
                // if (
                //     iframe.contentWindow.location.href.indexOf("access_token=") !== -1
                // ) {
                //     // extract the access token from the URL            
                //     //var accessToken = iframe.contentWindow.location.hash.substring(1);
                //     //console.log("url ->>>>" + iframe.contentWindow.location);
                //     //console.log("AccessTokenCome ->>>>" + accessToken);
                //     // use the access token to make requests to the Graph API
                //     // ...
                // }
            });

            // add the iframe to the document
            document.body.appendChild(iframe);
        }


    }

    removeOutlookSilentAuth() {
        var targetIFrame = document.getElementById('ouInte');
        if (targetIFrame)
            document.body.removeChild(targetIFrame);
    }

}
